import { motion } from "framer-motion";
import { container, itemFade } from "./Animations";
import portrait from "../assets/portrait.webp";
import { Caption } from "./Caption";

export default function Interest() {
    return (
        <motion.div
            variants={container}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.25 }}
            className="container flex flex-col lg:flex-row mx-auto my-24 gap-9 justify-between"
        >
            <motion.div className="basis-1/2">
                <motion.div
                    variants={itemFade}
                    className="text-3xl text-zinc-900 font-Syne font-semibold mb-8"
                >
                    Currently…
                </motion.div>
                <motion.div
                    variants={itemFade}
                    className="text-base text-zinc-800"
                >
                    <p className="mb-4">
                        I’m working with clients such as General Motors,
                        improving their web products and experiences. I also
                        design at Minimis for niche businesses.
                    </p>
                    <p className="mb-4">
                        If I’m not working, I can be found probably doing the
                        following:
                    </p>
                    <ul className="ml-4">
                        <li>Parenting my good dog Winston</li>
                        <li>Immersing myself in the Chicago food scene</li>
                        <li>Listening to podcast about life sciences and economics</li>
                        <li>Appreciating city architecture and history</li>
                        <li>Staying active by strength training, hiking, and climbing</li>
                    </ul>
                </motion.div>
            </motion.div>

            <motion.div className="md:basis-1/3 sm:display-none">
                <motion.div
                    variants={itemFade}
                    className="text-lg text-zinc-800 font-Overpass font-light"
                >
                    <div>
                        <img
                            src={portrait}
                            alt="Me, Justin"
                            className="rounded-md object-cover shadow w-full h-1/5"
                        />
                        {/* <Caption>Me getting some sun</Caption> */}
                    </div>
                </motion.div>
            </motion.div>
        </motion.div>
    );
}
