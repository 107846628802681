import Navbar from "../components/Navbar";
import Introduction from "../components/Introduction";
import CoreValues from "../components/CoreValues";
import SkillsTools from "../components/SkillsTools";
import Interest from "../components/Interest";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import { main_containerFade, sectionFade } from "../components/Animations";
import PhotoGallery from "../components/PhotoGallery";

export default function About() {
    return (
        // bg-ball bg-no-repeat bg-auto bg-top-center
        <div className="bg-body text-zinc-900 font-Overpass font-light">
            <div className="container mx-auto px-8 md:px-12 lg:px-56">
                {/* <!-- Navbar --> */}
                <Navbar />

                <motion.div
                    variants={main_containerFade}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true }}
                >
                    {/* <!-- Introduction --> */}
                    <motion.div variants={sectionFade}>
                        <Introduction />
                    </motion.div>

                    {/* <!-- Banner image -->
                        <motion.div variants={sectionFade} initial="hidden" whileInView="show" viewport={{once:true, amount:.5}}>
                        <img src={sedona} alt="Justin overlooking the majestic landscapes of Sedona National Park." className="container flex mx-auto mt-24 rounded-lg h-72 object-cover object-center w-full mb-10 sm:mb-24 lg:mb-24"></img>
                        </motion.div> */}

                    {/* <!-- Why Design? --> */}
                    <motion.div
                        variants={sectionFade}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.7 }}
                    >
                        <CoreValues />
                    </motion.div>

                    {/* <!-- Photo Gallery --> */}
                    <PhotoGallery />

                    {/* <!-- Skills & Tools --> */}
                    <SkillsTools />

                    {/* <!-- Interest --> */}
                    <Interest />

                    {/* <!-- Resume Link --> */}
                    {/* <motion.a variants={sectionFade} initial="hidden" whileInView="show" viewport={{once:true, amount:.5}} href="justin_yambao_resume.pdf" target="_blank" className="text-xl transition-color underline decoration-transparent decoration-wavy underline-offset-4 hover:decoration-emerald-400 duration-300 ease-out">
                            View My Resume →
                        </motion.a> */}

                </motion.div>
            </div>

            {/* <!-- Footer --> */}
            <Footer />
        </div>
    );
}
