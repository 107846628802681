import { motion } from "framer-motion";
import { sectionFade } from "./Animations";

export const ProjectBackground = ({ title, context, role }) => {
    return (
        <motion.div variants={sectionFade}>
            <div className="text-base">
                <p className=" text-lg font-semibold mb-4">{title}</p>
                <div className="text-base grid md:grid-cols-3 grid-cols-2 gap-x-12 gap-4">
                    <p className="mb-2 col-span-2 md:col-span-1">{context}</p>
                    <p className="col-span-2 md:col-span-1">{role}</p>
                </div>
            </div>
        </motion.div>
    );
};
