import { motion } from "framer-motion";
import {
    container,
    headerFade_2,
    itemFade,
    corevalues,
} from "../components/Animations";

export default function CoreValues() {
    return (
        <motion.div variants={corevalues} className="mb-36">
            <motion.div
                variants={itemFade}
                className="text-3xl mb-8 text-zinc-900"
            >
                <div className="text-3xl mb-2 text-zinc-900 font-Syne font-semibold">
                    Beliefs
                </div>
                {/* <div className="text-lg text-zinc-800">Keen on developing my mental toolbox, I take on new challenges to help me better understand people. Through this, my answer of “why design” has evolved.</div> */}
            </motion.div>
            {/* <motion.div variants={headerFade_2} className="text-lg mb-8 text-zinc-900">Keen on developing my mental toolbox, I take on new challenges to help me better understand people. Through this, my answer of “why design” has evolved.</motion.div> */}
            <motion.div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 md:gap-x-8 gap-4 mb-24 text-md">
                <motion.div variants={itemFade} className="">
                    <div className=" text-zinc-900 mb-2 font-medium">Conscious</div>
                    <p className="text-base text-zinc-800">
                        I aim to build net-positive products and I place sustainability, transparency, and ethics as the guiding principles of my beliefs.
                    </p>
                </motion.div>
                <motion.div variants={itemFade} className="">
                    <div className=" text-zinc-900 mb-2 font-medium">Curiosity</div>
                    <p className="text-base text-zinc-800">
                        I've always been fascinated by understanding how and why
                        things work, whether it's tackling industry challenges
                        or working with constraints.
                    </p>
                </motion.div>
                <motion.div variants={itemFade} className="">
                    <div className=" text-zinc-900 mb-2 font-medium">People</div>
                    <p className="text-base text-zinc-800">
                        Design, at its core, harbors human interaction. Growing
                        products that can scale alongside the audience they
                        serve is incredibly fulfilling.
                    </p>
                </motion.div>
            </motion.div>
        </motion.div>
    );
}
