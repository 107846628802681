import { motion } from "framer-motion";
import { sectionFade } from "./Animations";

export const ProjectHeader = ({ title, subtitle, img }) => {
    return (
        <motion.div variants={sectionFade} className="mt-16 mb-8 border-b border-zinc-400">
            {img && (
                <img
                    src={img}
                    className="object-cover object-center rounded-md shadow-sm w-full h-96 my-auto mb-8"
                    alt="hero"
                />
            )}
            <h1 className="mb-4">{title}</h1>
            <p className="mt-1 mb-8">{subtitle}</p>
        </motion.div>
    );
};
