import { motion } from "framer-motion";
import { FooterFade } from "../components/Animations";

export default function Footer({ dark = true }) {
    const color = dark === true ? "text-zinc-800" : "text-zinc-800";
    return (
        <motion.div
            variants={FooterFade}
            viewport={{ once: true }}
            className="mt-36 py-6"
        >
            <span
                className={`flex container mx-auto text-center px-8 md:px-0 lg:px-16 py-2 rounded-lg justify-center ${color}`}
            >
                Built using React, Framer & Tailwind. Copyright Ⓒ{" "}
                {new Date().getFullYear()} Okapi House.
            </span>
        </motion.div>
    );
}
