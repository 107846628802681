import { ProjectHeader } from "../../components/ProjectHeader";
import { ProjectLayout } from "../../components/ProjectLayout";
import { ProjectSection } from "../../components/ProjectSection";
import { ProjectBackground } from "../../components/ProjectBackground";

//images
import stateHero from "../../assets/stateHero.png";
import goal1 from "../../assets/state_Frame_603.png";
import goal2 from "../../assets/state_Frame_602.png";
import goal3 from "../../assets/state_Frame_601.png";
import process from "../../assets/state_Frame_102.png";
import companalysis from "../../assets/state_ca.png";
import statetree from "../../assets/state_tree.png";
import observation1 from "../../assets/state_Frame_600.png";
import observation2 from "../../assets/state_Frame_599.png";
import dashboard from "../../assets/state_dashboard.png";
import billing from "../../assets/state_billing.png";
import policy from "../../assets/state_policies.png";
import more from "../../assets/state_more.png";
import claim from "../../assets/state_claim.png";
import cards from "../../assets/state_Group_26.png";
import solution1 from "../../assets/state_Group_1.jpg";
import solution2 from "../../assets/state_Group_2.jpg";
import solution3 from "../../assets/state_Group_3.jpg";
import solution4 from "../../assets/state_snippet.png";
import solution5 from "../../assets/state_Group_4.jpg";
import results1 from "../../assets/state_Frame_597.png";
import results2 from "../../assets/state_Frame_598.png";
import reflection1 from "../../assets/state_Frame_595.png";
import reflection2 from "../../assets/state_Frame_594.png";
import reflection3 from "../../assets/state_Frame_596.png";
import { Icon } from "../../components/Icon";
import { Caption } from "../../components/Caption";
import { Column } from "../../components/Column";
import { Content } from "../../components/Content";
import { CursorClickIcon, MapIcon, SearchIcon, DatabaseIcon, LockClosedIcon, ClockIcon, ZoomOutIcon, ExclamationIcon, CodeIcon, UserGroupIcon, TrendingUpIcon, CheckCircleIcon, ScaleIcon, KeyIcon, CursorArrowRippleIcon, SmileIcon } from '@heroicons/react/outline';

export const State = () => {
    return (
        <ProjectLayout>
            {/* <!-- Header --> */}

            <ProjectHeader
                img={stateHero}
                title="Building from the Ground Up"
                subtitle="State Auto Insurance - Mobile App"
            />
            <ProjectBackground
                // title="Overview"
                context="In 2022, State Auto had launched their mobile app to reach customers and improve brand perception."
                role="I led the design, strategy and collaborated frequently with designers, researchers, engineers and product owners to develop a product that’ll strengthen State Auto’s digital footprint."
            />

            {/* <!-- Overview --> */}
            <ProjectSection>
                {/* <h2 className="text-emerald-700">Overview</h2> */}
                {/* <Content className="md:w-2/3 w-full">
                    <h3 className="text-emerald-700">Background</h3>
                    <p>
                        In an increasingly competitive insurance market, State Auto recognized the need to expand their digital presence. The insurance industry had undergone a shift, with customers expecting more seamless, intuitive digital experiences comparable to what they encounter in other industries, like mobile banking apps. State Auto had already taken steps to develop web products aimed at meeting customer needs, but these efforts did not yet fully delivered on customer expectations or engagement.
                    </p>
                </Content> */}
                <Content className="md:w-2/3 w-full">
                    <h3 className="">What prompted us to build a mobile app?</h3>
                    <p className="font-medium underline underline-offset-8">Competition</p>
                    <p>
                        State Auto had rapidly transformed their digital offerings over the past few years; however, they continued faced pressure from competition and customer expectations.
                    </p>
                    <p>
                        Competitor mobile app experiences were more well equiped to build and maintain relationships with their customers. Customers also weren't resonating with the brand like in the past, often moving to other competitors.
                    </p>
                </Content>
                {/* <Content>
                    <h3 className="text-emerald-700">Goal</h3>
                    <div className="grid md:grid-cols-3 grids-cols-1 gap-x-12">
                        <Column>
                            <p className="font-medium">Ease of access</p>
                            <p>Can this address issues related to access?</p>
            
                        </Column>

                        <Column>
                            <p className="font-medium">Increase engagement</p>
                            <p>How can we better serve customers?</p>
               
                        </Column>

                        <Column>
                            <p className="font-medium">
                                Mitigate system strain
                            </p>
                            <p>
                                How can this product co-exist with other State
                                Auto products? - Service Design
                            </p>
    
                        </Column>
                    </div>
                </Content> */}
            </ProjectSection>

            {/* <!-- Background --> */}
            {/* <ProjectSection>
                <Content>
                    <h2 className="text-emerald-700">Background</h2>
                    <p className="md:w-2/3 w-full">
                        The success of State Auto’s Submit a Claim Experience
                        and Customer Self-Service Portal were a driving force in
                        deciding suitable design opportunities.
                    </p>
                </Content> */}
            {/* <Content>
                    <h3 className="text-emerald-700">Process</h3>
                    <p>Facilitated UX through several ways:</p>
                    <img src={process} alt="process" />
                </Content> */}
            {/* <Content>
                    <h3 className="text-emerald-700">Why Mobile?</h3>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-x-12">
                        <Column>
                            <p className="font-medium">Industry Expectations</p>
                            <p>
                                As insurance shifts to become more innovative,
                                traditional approaches like calling or a website
                                won't be enough to sustain growth in the long
                                term.
                            </p>
                        </Column>
                        <Column>
                            <p className="font-medium">Customer Engagement</p>
                            <p>
                                Aside from the desktop products and website,
                                we’re limited with the way customers interact
                                with State Auto as a brand
                            </p>
                        </Column>
                    </div>
                </Content>
            </ProjectSection> */}

            {/* <!-- Research --> */}
            <ProjectSection>
                <Content className="md:w-2/3 w-full">
                    <h2 className="text-blue-600">Getting started</h2>
                    <p>
                        Along with the existing products serving as a framework,
                        external research was conducted to understand the
                        industry’s expectations.
                    </p>
                </Content>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-x-12 gap-4">
                    <Column>
                        <h3 className="text-blue-800">What we can learn from others</h3>
                        <p>
                            By looking at industry competitors,
                            what content are they showcasing in their apps, and
                            how can we leverage some of these features in our
                            app?
                        </p>
                    </Column>
                    <div>
                        <img src={companalysis} alt="icon" />
                        <Caption>
                            Competitive analysis screenshot
                        </Caption>
                    </div>
                </div>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-x-12 gap-4">

                    <Column>
                        <h3 className="text-blue-800">Matching the mental model</h3>
                        <p>
                            After the competitive analysis, a tree test helped
                            determine where users would expect to find common
                            insurance info.
                        </p>
                    </Column>
                    <div>
                        <img src={statetree} alt="icon" />
                        <Caption>
                            In-App Navigation Tree Testing Screenshot
                        </Caption>
                    </div>
                </div>
                <Content>
                    <Column className="md:w-2/3 w-full">
                        <h3 className="text-blue-800">Building empathy</h3>
                        <p>
                            Listening to customer service calls was a great way
                            for me to see the current scenario customers are
                            facing.
                        </p>
                    </Column>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-x-12 gap-4">
                        <Column>
                            {/* <Icon src={observation1} alt="observation1" /> */}
                            <p div className="font-medium">
                                Key learnings
                            </p>
                            <ul>
                                <li>
                                    Frustration in the tone of many customers
                                    when related to filing a claim and paying a
                                    bill
                                </li>
                                <li>
                                    A good amount of downtime when it came to
                                    gathering customer info for a claim
                                </li>
                            </ul>
                        </Column>
                        <Column>
                            {/* <Icon src={observation2} alt="observation2" /> */}
                            <p className="font-medium">
                                Insights
                            </p>
                            <ul>
                                <li>
                                    Tons of friction having CSRs do simple tasks
                                    like stopping payments or filing a claim
                                </li>
                                <li>
                                    Audible cues and tone helped when
                                    empathizing with the customer
                                </li>
                                <li>
                                    Interesting to see how this product I’m
                                    designing for can impact other areas of the
                                    business
                                </li>
                            </ul>
                        </Column>
                    </div>
                </Content>
            </ProjectSection>

            {/* Exploration & Validation */}
            <ProjectSection>
                <h2 className="text-orange-600">Exploration</h2>
                <Content>
                    <h3 className="text-orange-800">Earliest iterations</h3>
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-x-12">
                        <div className="md:col-span-4 col-span-1">
                            <img src={dashboard} alt="icon" />
                            <Caption>Dashboard (low fidelity)</Caption>
                        </div>
                        <div className="md:col-span-4 col-span-1">
                            <img src={billing} alt="icon" />
                            <Caption>Billing (low fidelity)</Caption>
                        </div>
                        <div className="md:col-span-4 col-span-1">
                            <img src={policy} alt="icon" />
                            <Caption>Policy (low fidelity)</Caption>
                        </div>
                        {/* <div className="md:col-span-5 col-span-1">
                            <img src={more} alt="icon" />
                            <Caption>Hi-Fi for More</Caption>
                        </div>
                        <div className="md:col-span-7 col-span-1">
                            <img src={claim} alt="icon" />
                            <Caption>Hi-Fi for Submit a Claim</Caption>
                        </div> */}
                    </div>
                </Content>
                <Content>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-x-12">
                        <Column>
                            <h3 className="text-orange-800">Frequently testing hypothesis</h3>
                            <p>Opting for talk-aloud unmoderated user testing allowed us to capture raw impressions to further understand user behavior as we explored different hypothesis.</p>
                        </Column>
                        <div>
                            <img src={cards} alt="icon" />
                            <Caption>
                                Example of policy ID card layouts we tested
                            </Caption>
                        </div>
                    </div>
                </Content>
                <Content>
                    <h3 className="text-orange-800">Challenges</h3>
                    <div className="grid md:grid-cols-3 grid-cols-1 gap-x-12 gap-6 md:w-full w-full">
                        <Column>
                            <DatabaseIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">Data usage</p>
                            <p>
                                Being unable to use old
                                user data properly (loyal customer base) resulted in solutions for temporary edge cases.
                            </p>
                        </Column>
                        <Column>
                            <KeyIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">Legacy technology</p>
                            <p>
                                There was difficulty with outage handling, which also happened to result in no backups when submitting claims.
                            </p>
                        </Column>
                        <Column>
                            <DatabaseIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">Research capabilities</p>
                            <p>
                                Limited UX
                                research resources usage made it
                                difficult to conduct studies at scale.
                            </p>
                        </Column>
                        {/* <Column>
                            <p className="font-medium">Healthy Pushback</p>
                            <ul>
                                <li>
                                    Knowing when to push, and when to compromise
                                </li>
                                <li>
                                    User 1st approach - avoid dark design
                                    patterns
                                </li>
                                <li>
                                    Features not solely on business wants -
                                    customization for the sake of having it
                                </li>
                            </ul>
                        </Column> */}
                    </div>
                </Content>
            </ProjectSection>

            {/* <!-- Solution --> */}
            <ProjectSection>
                <h2 className="text-emerald-600">Solution</h2>

                <Content>
                    <h3 className="text-emerald-700">Highlights</h3>
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-x-12">
                        <div className="md:md:col-span-6 col-span-1">
                            <img src={solution1} alt="icon" />
                            <Caption>Billing - Make a Payment</Caption>
                            <img src={solution3} alt="icon" />
                            <Caption>More - FAQ, Live Chat, Call Us</Caption>
                        </div>
                        <div className="md:md:col-span-6 col-span-1">
                            <img src={solution2} alt="icon" />
                            <Caption>
                                Policy - Auto Policy - Vehicle Details
                            </Caption>
                        </div>
                        {/* <div className="md:md:col-span-4 col-span-1">
                            
                        </div> */}
                        {/* <div className="md:md:col-span-4 col-span-1">
                            <img src={solution4} alt="icon" />
                            <Caption>
                                Snippet From the Mobile Component Library
                            </Caption>
                        </div> */}
                        <div className="md:md:col-span-12 col-span-1">
                            <img src={solution5} alt="icon" />
                            <Caption>
                                Submit a Claim - Auto - Insured Vehicle Step
                            </Caption>
                        </div>
                    </div>
                </Content>
                <Content>
                    <h3 className="text-emerald-800">The impact</h3>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-x-12 gap-6">
                        <Column>
                            {/* <Icon src={results1} alt="results1" /> */}
                            {/* <ClockIcon className="h-6 w-6 text-zinc-800" /> */}
                            <p className="font-medium">Positive reception</p>
                            <p>
                                Users described the experience as <span className="font-medium bg-indigo-100 text-indigo-800">
                                    concise and
                                    accessible
                                </span>{" "}
                                when it came to how information was displayed.{" "} It was <span className="font-medium bg-indigo-100 text-indigo-800">
                                    refreshing
                                </span>{" "}
                                to see a mobile app from a 100-year-old company.
                            </p>
                        </Column>
                        <Column>
                            {/* <Icon src={results2} alt="results2" /> */}
                            {/* <ClockIcon className="h-6 w-6 text-zinc-800" /> */}
                            <p className="font-medium">Saving the business time</p>
                            <p>
                                {/* What typically took{" "}
                                <span className="font-medium bg-orange-100">
                                    20 min to an hour
                                </span>{" "}
                                to file a claim by phone now takes{" "}
                                <span className="font-medium bg-orange-100">
                                    10-15 minutes
                                </span>{" "}
                                to file from within the app based on session
                                data */}
                                <span className="font-medium bg-indigo-100 text-indigo-800">Reduced time spent filing a claim by at least 50%</span> based on app session data compared to calling customer support.
                            </p>
                        </Column>
                    </div>
                </Content>
                <Content>
                    <h3 className="text-emerald-800">My takeaways</h3>
                    <div className="grid md:grid-cols-3 grid-cols-1 gap-x-12 gap-6">
                        <Column>
                            {/* <Icon src={reflection1} alt="reflection1" /> */}

                            <p className="font-medium">Breaking it down</p>
                            <p>
                                Simplifying complex processes (like submitting a claim) by making them more digestible was a challenge
                            </p>
                        </Column>
                        <Column>
                            {/* <Icon src={reflection2} alt="reflection2" /> */}

                            <p className="font-medium">
                                Build for efficiencies
                            </p>
                            <p>
                                I migrated mobile components to the design
                                system, and I learned a ton contributing to it.
                            </p>
                        </Column>
                        <Column>
                            {/* <Icon src={reflection3} alt="reflection3" /> */}

                            <p className="font-medium">Context is key</p>
                            <p>
                                Prototypes were crucial for stakeholders to understand potential and for the engineering team to grasp intended interaction.
                            </p>
                        </Column>
                    </div>
                </Content>
                {/* <Content>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-x-12 md:w-2/3 w-full">
                        <Column>
                            <h3 className="text-emerald-700">Aha Moments</h3>
                            <p>
                                A customer-facing mobile app was expressed in
                                other insurance agent interviews when asked if
                                “...customers having any other expectations from
                                State Auto”?
                            </p>
                        </Column>
                        <Column>
                            <h3 className="text-emerald-700">Role</h3>
                            <div>
                                <p>
                                    <span className="font-medium">
                                        UX Designer -
                                    </span>{" "}
                                    Justin Yambao
                                </p>
                                <p>
                                    <span className="font-medium">
                                        UX Designer -
                                    </span>{" "}
                                    Abhishek Deshpande
                                </p>
                                <p>
                                    <span className="font-medium">
                                        Researcher -
                                    </span>{" "}
                                    Nathan Paul
                                </p>
                            </div>
                        </Column>
                    </div>
                </Content> */}
            </ProjectSection>
        </ProjectLayout>
    );
};
