import { motion } from "framer-motion";
// import {container, headerFade_2, itemFade, corevalues} from '../components/Animations';
import hawaii from "../assets/hawaii.webp";
import dog from "../assets/dog.webp";
import detroit from "../assets/detroit.webp";
import food from "../assets/food.webp";
import { sectionFade_ProjectGallery } from "./Animations";
import { Caption } from "./Caption";

export default function PhotoGallery() {
    return (
        <motion.div
            variants={sectionFade_ProjectGallery}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 md:gap-x-4 gap-8 flex-row flex-wrap justify-between md:flex-nowrap"
        >
            <motion.div className="h-72 sm:h-64">
                <img
                    src={hawaii}
                    alt="Hawaiian Forest Landscape"
                    className="h-full w-full object-cover object-bottom rounded-md shadow"
                ></img>
            </motion.div>
            <motion.div className="h-72 sm:h-64">
                <img
                    src={detroit}
                    alt="Detroit building architecture"
                    className="h-full w-full object-cover object-bottom rounded-md shadow"
                ></img>
            </motion.div>
            <motion.div className="h-72 sm:h-64">
                <img
                    src={dog}
                    alt="My 15lb dog Winston"
                    className="h-full w-full object-cover object-center rounded-md shadow"
                ></img>
            </motion.div>
            <motion.div className="h-72 sm:h-64">
                <img
                    src={food}
                    alt="Ramen shop I lived above."
                    className="h-full w-full object-cover object-bottom rounded-md shadow"
                ></img>
            </motion.div>
        </motion.div>
    );
}
